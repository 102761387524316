<template lang="pug">
  .common-settings-form
    .title
      span {{ $t("company_system.rate_plan_settings.rate_plan.details.common_settings.title") }}
    .form
      .form-row
        .form-row-label
          FormFieldLabel(:title="$t('company_system.rate_plan_settings.rate_plan.details.common_settings.standard_options')")
        .form-row-field
          AppDropdown(
            multiple
            allow-empty
            removable-labels
            :is-item-disabled="isSelectableOptionSelected"
            :items="options"
            :value="selectedStandardOptions"
            :loading="optionsLoading"
            @select="selectStandardOptions"
          )
      .form-row
        .form-row-label
          FormFieldLabel(:title="$t('company_system.rate_plan_settings.rate_plan.details.common_settings.selection_options')")
        .form-row-field
          AppDropdown(
            multiple
            allow-empty
            removable-labels
            :is-item-disabled="isStandardOptionSelected"
            :items="options"
            :value="selectedSelectableOptions"
            :loading="optionsLoading"
            @select="selectSelectableOptions"
          )
      .form-row
        .form-row-label
          FormFieldLabel(:title="$t('company_system.rate_plan_settings.rate_plan.details.common_settings.rounding')")
          span.required {{ $t('company_system.req') }}
        .form-row-label
          BAlert.warning(
            show
            variant="warning"
            :title="$t('company_system.rate_plan_settings.rate_plan.details.common_settings.rounding_explanation')"
          )
            | {{ $t('company_system.rate_plan_settings.rate_plan.details.common_settings.rounding_explanation') }}
        .form-row-field
          .rounding-radio.flex-row
            AppRadioButton(
              :value="commonSetting.drop_off_available"
              @change="setDropOffAvailable(true)"
            )
            .label
              span {{ $t('company_system.rate_plan_settings.rate_plan.details.common_settings.can_be_rounded_down') }}
          .rounding-radio.flex-row
            AppRadioButton(
              :value="!commonSetting.drop_off_available"
              @change="setDropOffAvailable(false)"
            )
            .label
              span {{ $t('company_system.rate_plan_settings.rate_plan.details.common_settings.can_not_be_rounded_down') }}
      .form-row
        .form-row-label
          FormFieldLabel(:title="$t('company_system.rate_plan_settings.rate_plan.details.common_settings.explanation')")
        .form-row-field.explanation
          textarea(
            type="text"
            :rows="10"
            :class="{ 'invalid': $v.commonSetting.description.$error }"
            v-model="commonSetting.description"
          )
          span.text-length(
            :class="{ 'invalid': !$v.commonSetting.description.maxLength }"
          )
            | {{ descriptionLabel }}
    FormActionBar(
      delete-enabled
      @delete="$emit('remove-plan')"
      @save="handleSave"
    )
</template>

<script>
  const EXPLANATION_MAX_LENGTH = 1024

  // misc
  import { get, map, filter, isEqual } from "lodash-es"

  // mixins
  import withValidations from "@/mixins/withValidations"
  import withStoreModule from "@/mixins/withStoreModule"
  import withScrollTop from "@/mixins/withScrollTop"

  // stores
  import optionsModule from "@/config/store/company_system/basic_settings/options"
  import planCommonModule from "@/config/store/company_system/rate_plan_settings/rate_plan/common_settings"

  const optionsMixin = withStoreModule(optionsModule, {
    name: "optionsMixin",
    readers: {
      options: "items",
      optionsLoading: "loading"
    },
    actions: { fetchOptions: "FETCH_ITEMS" }
  })

  const companiesPlanCommonSettingMixin = withStoreModule(planCommonModule, {
    resetState: true,
    name: "companiesPlanCommonSetting",
    readers: {
      commonSetting: "item",
      initialCommonSetting: "initialItem"
    },
    actions: {
      fetchCommonSetting: "FETCH_ITEM",
      updateCommonSetting: "UPDATE_ITEM"
    },
    mutations: {
      setDirty: "SET_DIRTY",
      resetCommonSetting: "RESET_ITEM"
    }
  })

  const validationsMixin = withValidations(({ maxLength }) => ({
    commonSetting: {
      description: {
        maxLength: maxLength(EXPLANATION_MAX_LENGTH)
      }
    }
  }))

  export default {
    components: {
      FormActionBar: () => import("../../../FormActionBar"),
      FormFieldLabel: () => import("@/components/elements/FormFieldLabel"),
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppRadioButton: () => import("@/components/elements/AppRadioButton"),
      AppNumberInput: () => import("@/components/elements/AppNumberInput")
    },

    mixins: [optionsMixin, companiesPlanCommonSettingMixin, withScrollTop, validationsMixin],

    props: {
      planId: {
        type: Number,
        required: false
      },
      active: {
        type: Boolean,
        default: false
      }
    },

    watch: {
      active: {
        handler() {
          if (this.active) {
            this.resetCommonSetting()
          } else {
            this.setDirty(false)
          }
        }
      },

      hasUnsavedChanges(useConfirm) {
        this.setDirty(useConfirm)
      }
    },

    async mounted() {
      await this.fetchOptions({ pagination: { _disabled: true } })
      this.fetchCommonSetting(this.planId)
    },

    methods: {
      textLength(length, maxLength) {
        return `(${length}/${maxLength})`
      },

      setDropOffAvailable(dropOffAvailable) {
        this.commonSetting.drop_off_available = dropOffAvailable
      },

      isStandardOptionSelected({ id }) {
        return get(this.commonSetting, "standard_option_ids", []).includes(id)
      },

      isSelectableOptionSelected({ id }) {
        return get(this.commonSetting, "selectable_option_ids", []).includes(id)
      },

      selectStandardOptions(options) {
        this.commonSetting.standard_option_ids = map(options, "id")
      },

      selectSelectableOptions(options) {
        this.commonSetting.selectable_option_ids = map(options, "id")
      },

      handleSave() {
        this.validateAttributes()
        if (!this.isValid) {
          this.$nextTick(() => {
            this.scrollTo({ target: ".is-invalid", inline: "center" })
          })
          return
        }
        this.sendRequest().then(() => {
          this.cancelValidation()
          this.$emit("refresh-plan")
        })
      },

      async sendRequest() {
        await this.updateCommonSetting({ planId: this.planId, common_setting: this.commonSetting })
      }
    },

    computed: {
      hasUnsavedChanges() {
        return !isEqual(this.commonSetting, this.initialCommonSetting)
      },

      descriptionLength() {
        return this.commonSetting.description?.length || 0
      },

      descriptionLabel({ descriptionLength }) {
        return this.textLength(descriptionLength, EXPLANATION_MAX_LENGTH)
      },

      selectedStandardOptions() {
        return filter(this.options, option => this.isStandardOptionSelected(option))
      },

      selectedSelectableOptions() {
        return filter(this.options, option => this.isSelectableOptionSelected(option))
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/price-management/basic-settings.sass"
  @import "@/assets/styles/variables.sass"

  .common-settings-form
    +styled-inputs

    input
      height: 38px

    .is-invalid
      ::v-deep
        input
          +default-invalid-input

    padding-bottom: 10px
    .flex-row
      display: flex
      justify-content: left

    .title
      height: 40px
      border-left: 13px solid $default-purple-light
      padding: 10px
      font-weight: 700
      font-size: 16px

    .form
      padding: 5px

      &-row
        display: flex
        flex-direction: column
        margin-top: 20px

        &-label
          font-style: normal
          font-weight: 200
          font-size: 13px
          line-height: 19px

          .warning
            padding: 5px 7px
            margin-bottom: 7px

          span.required
            margin-left: 10px
            font-size: 12px
            color: $default-red

        &-field
          margin-top: 5px
          font-weight: 400
          font-size: 16px

          .text-length
            font-size: 12px
            color: $default-gray-medium

            &.invalid
              color: $default-red

          .label
            font-size: 13px
            margin-left: 10px

          .rounding-radio
            margin-bottom: 10px

          &.explanation
            display: flex
            flex-direction: column

            textarea
              width: 100%
              margin-bottom: 5px
              border: 1px solid $default-purple-light
              border-radius: 5px

              &.invalid
                +default-invalid-input

            &.input
              .label,
              .app-radio-button
                margin-top: 7px
                margin-right: 7px

              input
                width: 60px


          .app-select
            padding: 0
</style>
